import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchOrgPosts, changePostStatus } from '../../firebase';


class PeerPost extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // userID: this.props.user.id,
            peerID: this.props.user.peerID ? this.props.user.peerID : this.props.user.id,
            posts: [],
        };
    }

    componentDidMount() {
        if (this.props.location.state.orgID !== '') {
            fetchOrgPosts(this.props.location.state.orgID, this.state.peerID, this.setPosts);
        }
    }

    setPosts = (posts) => {
        this.setState({ posts });
    }

    postAction = (postID, status) => {
        changePostStatus(this.props.location.state.orgID, postID, status, this.successCallback);
    }

    successCallback = () => {
        this.componentDidMount();
    }

    render() {
        return (
            <div className="userPostList">
                {
                    this.state.posts.length > 0 ? (
                        this.state.posts.map((post, index) => {
                            if(post.status == 'pending'){
                                return (
                                    <div className="userPostTile" key={index}>
                                        <div className="userPostText">
                                            <h6>{post.title}</h6>
                                            <p>{post.text}</p>
                                            <div className='dialog'>
                                                <button className='blueButton btn btn-primary' onClick={(e) => this.postAction(post.firebaseID, 'confirm')}>Confirm</button>
                                                <button className='blueButton btn btn-primary' onClick={(e) => this.postAction(post.firebaseID, 'reject')}>Reject</button>
                                            </div>
                                        </div>
                                    </div>
                                );
                            } else if(post.status == 'confirm'){
                                return (
                                    <div className="userPostTile" key={index}>
                                        <div className="userPostText">
                                            <h6>{post.title}</h6>
                                            <p>{post.text}</p>
                                            <div className='dialog'>
                                                <button className='blueButton btn btn-primary' onClick={(e) => this.postAction(post.firebaseID, 'reject')}>Reject</button>
                                            </div>
                                        </div>
                                    </div>
                                );
                            } else {
                                return (
                                    <div className="userPostTile" key={index}>
                                        <div className="userPostText">
                                            <h6>{post.title}</h6>
                                            <p>{post.text}</p>
                                            <div className='dialog'>
                                                <button className='blueButton btn btn-primary' onClick={(e) => this.postAction(post.firebaseID, 'confirm')}>Confirm</button>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                        })
                    ) : (
                        <div className="emptyData">
                            This peer has no post
                        </div>
                    )
                }
            </div>
        );
    }
}

const mapStateToProps = reduxState => (
    {
        orgID: reduxState.orgs.selectedOrgID,
        allUsers: reduxState.users.orgUsers,
    }
);

export default withRouter(connect(mapStateToProps, { fetchOrgPosts, changePostStatus })(PeerPost));
