/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Routes, { createOrgRoute } from '../helpers/Routes';
import Roles from '../helpers/Roles';

export default function MarketListRow(props) {
    return (
      <>
          { 
              props.data && props.data.map((item)=>{
               // console.log('eeee',item)
                return (
                    
                    <div class="listRow table-row link" role="button" >
                    <div class="name">{item.name}</div>
                    <div class="edit">
                      <Link  to={{
    pathname: "/editList",
    state: { Alldata : item }
  }} >
                        <i class="fas fa-edit"></i>
                      </Link>
                    </div>
                  </div>
                );
               
              })
          }                                
          </>
    );
  }

