import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Input } from 'reactstrap';
import { connect } from 'react-redux';
import { updateAccount, fetchRole, fetSingleAccount } from '../firebase';
import Roles from '../helpers/Roles';
import { setErrorMessage } from '../redux/DispatchHelpers';

// Collects data for account to be pushed to accountRequests created by Cloud Function
class EditAccount extends Component {
    constructor(props) {
        super(props);

        // Set the organization to the first org in the dropdown if user is super admin,
        // or to the current user's org if the user is admin
        let stateOrgID = '';
        if (props.role === Roles.ADMIN) {
            stateOrgID = props.orgID;
        } else {
            stateOrgID = props.selectedOrgID.length > 0
                ? props.selectedOrgID : props.organizations[0].orgID;
        }
        
        // Form fields
        this.state = {
            // serviceUser: null,
            name: '',
            email: '',
            number: '',
            orgID: stateOrgID,
            peerID: '',
            selectedPeerID: '',
            selectedRole: '',
            selectedOrganizationID: '',
            role: Roles.PEER,
            userID: props.location.state.userID,
        };

       // console.log(props.location.state);
    }

    componentDidMount() {
        if (this.props.role === Roles.ADMIN) {
            this.props.fetchRole(this.props.selectedOrgID, Roles.PEER);
        }
        if (this.props.role === Roles.SUPER_ADMIN) {
            this.props.fetchRole(this.state.orgID, Roles.PEER);
        }
        const successCallback = (userResp) => {
            this.setState({ name: userResp.name, email: userResp.email, peerID: userResp.peerID, selectedPeerID: userResp.peerID, selectedOrganizationID: userResp.orgID, selectedRole: userResp.role });
        };
        this.props.fetSingleAccount(this.state.userID, successCallback);
    }

    componentDidUpdate(prevProps, prevState) {
    }

    onNameChange = (event) => {
        this.setState({ name: event.target.value });
    }

    onPeerChange = (event) => {
        this.setState({ peerID: event.target.value });
    }

    // When user submits form, send data to backend
    handleAccountRequest = (event) => {

        if (this.state.name === '' || this.state.email === '') {
            this.props.setErrorMessage('Please fill in all the required information');
            return;
        }

        if (this.state.selectedRole === Roles.SERVICE_USER && !this.state.peerID) {
            this.props.setErrorMessage('Please select a specific peer for this service user');
            return;
        }

        this.props.updateAccount(this.state.selectedOrganizationID, this.state.email.trim(), this.state.selectedRole, this.state.name.trim(), this.state.peerID, this.state.selectedPeerID, this.state.userID);
    }

    // Render a dropdown of each organization
    createOrganizationOptions = () => {
        const organizationOptions = Object.keys(this.props.organizations).map((id) => {
            const org = this.props.organizations[id];
            return (
                <option key={id} value={org.orgID}>{org.name}</option>
            );
        });
        return organizationOptions;
    }

    createPeerOptions = () => {
        const peerOptions = Object.keys(this.props.peers).map((id) => {
            const peer = this.props.peers[id];
            return (
                <option key={id} value={peer.id}>{peer.name}</option>
            );
        });
        return peerOptions;
    }

    render() {
        return (
            <div className="page view">
                <div className="pageHeader text-justify">
                    <div>
                        <h1>Update User</h1>
                        <p>
                            Fill out the form below with a update person&#39;s idenitfying and contact information to add them to your database.
                        </p>
                    </div>
                </div>
                <div id="createAccount">
                    <div id="createForm">
                        <div className="important">All fields are required.</div>
                        <div className="select">
                            <select value={this.state.role} className="form-control input-field" disabled="disabled" placeholder='Select role'>
                                {
                                    (this.state.selectedRole == Roles.SERVICE_USER)
                                        ? (<option value={Roles.SERVICE_USER}>Service User</option>)
                                        : (this.state.selectedRole == Roles.PEER) ? (<option value={Roles.PEER}>Peer</option>) : (<option value={Roles.MARKET_PLACE_USER}>Market Place</option>)
                                }
                            </select>
                        </div>
                        <Input placeholder="Full Name" onChange={this.onNameChange} className="input-field" value={this.state.name} />
                        <Input placeholder="Email Address" onChange={this.onEmailChange} className="input-field" disabled="disabled" value={this.state.email} />
                        {/* <Input placeholder="Phone Number" onChange={this.onNumberChange} className="input-field" value={this.state.number} /> */}
                        {(this.props.role === Roles.SUPER_ADMIN && this.state.role !== Roles.SUPER_ADMIN && this.state.selectedRole !== Roles.MARKET_PLACE_USER ) && (
                            <div className="select">
                                <div>
                                    <select placeholder="Organization" value={this.state.orgID} onChange={this.onOrgChange} disabled="disabled" className="form-control input-field">
                                        {this.createOrganizationOptions()}
                                    </select>
                                </div>
                            </div>
                        )}
                        {this.state.selectedRole === Roles.SERVICE_USER && this.state.selectedOrganizationID !== '' && this.state.selectedRole !== Roles.MARKET_PLACE_USER ? (
                            <div className="select">
                                <div>
                                    <select placeholder="Select a Peer" value={this.state.peerID} onChange={this.onPeerChange} className="form-control input-field">
                                        {this.createPeerOptions()}
                                    </select>
                                </div>
                            </div>
                        ) : (<div />)}
                        <div>
                            <Button className="blueButton btn-block" onClick={this.handleAccountRequest}>Update</Button>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = reduxState => (
    {
        organizations: reduxState.orgs.organizations,
        role: reduxState.auth.role,
        orgID: reduxState.auth.orgID, // for admins
        selectedOrgID: reduxState.orgs.selectedOrgID, // for super admins
        peers: reduxState.users.orgPeers,
        users: reduxState.users.orgUsers,
    }
);

export default withRouter(connect(mapStateToProps, { updateAccount, setErrorMessage, fetchRole, fetSingleAccount })(EditAccount));
