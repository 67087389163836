import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import MarketUsers from './MarketUsers';
import MarketList from './MarketList';


const tabs = {
    MARKET_USER: 'market_users',
    MARKET_LIST: 'market_list',
};


// Shows list/table of all an organization's service users
class MarketPlace extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tab: '',
        };
    }

    componentDidMount() {
        this.setState({ tab: tabs.MARKET_USER });
    }

    addNew = () => {
        this.props.history.push({
            pathname: '/createAccount',
        });
    }

    addNewList = () => {
        this.props.history.push({
            pathname: '/createList',
        });
    }

    // resetPasswords = () => {
    //   this.props.resetAllPasswords([...this.props.orgUsers, ...this.props.orgPeers, ...this.props.orgAdmins]);
    // }

    setTab = (tab) => {
        this.setState({ tab });
    }

    renderTableTabs = () => {
        // const { user, orgID } = this.props.location.state;
        return (
            <Tabs className="nav-fill"
                activeKey={this.state.tab}
                onSelect={this.setTab}
                id="peopleTabs"
            >
                <Tab className="peopleTab" eventKey={tabs.MARKET_USER} title="Market Users">
                    <MarketUsers />
                </Tab>
               
                <Tab className="peopleTab" eventKey={tabs.MARKET_LIST} title="Market List">
                    <MarketList />
                </Tab>
                

            </Tabs>
        );
    }


    render() {
        return (
            <div className="page view">
                <div className="pageHeader">
                    <div>
                        <h1>Market Place Management </h1>
                        <p>Manage market place users and list. </p>
                    </div>
                    {/* <Button className="resetAllPasswordsButton" id="purplebackground" onClick={this.resetPasswords}>
            Reset all passwords
          </Button> */}
                    <Button className="addButton" id="purplebackground" onClick={this.addNew}>
                        + Add New User
                    </Button>

                    <Button className="addButton" id="purplebackground" onClick={this.addNewList}>
                        + Add New List
                    </Button>
                </div>
                {/* <div>
          <Button onClick={this.sortByName}>Sort by name</Button>
        </div> */}
                <div className="table boxshadow">
                    {/* <p>Show: </p> */}
                    {this.renderTableTabs()}
                </div>
            </div>
        );
    }
}


export default withRouter(connect(null, {})(MarketPlace));
