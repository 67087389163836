/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-nested-ternary */
import React, { Component,useState } from 'react';
import { withRouter,useHistory, useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Input } from 'reactstrap';
import { connect } from 'react-redux';
import { firebase, auth, createMarketList } from '../firebase';
import { setErrorMessage } from '../redux/DispatchHelpers';


export default function CreateMarketList(props) {
    var [name,setName] = useState('');
    const navigate = useHistory(); 

    

    const onNameChange = (event) => {
        setName(event.target.value);
    };

   const handleAccountRequest = (event) => {
        

        if (name === '') {
            props.setErrorMessage('Please fill in all the required information');
            return;
        }
        createMarketList(name,()=>{
            navigate.goBack();
        });
 
    };

    return (
        <div className="page view">
        <div className="pageHeader text-justify">
            <div>
                <h1>Add a New List</h1>
            </div>
        </div>
        <div id="createAccount">
            <div id="createForm">
                
                <Input placeholder="Name" onChange={onNameChange} className="input-field" value={name} />
                <div>
                    <Button className="blueButton btn-block" onClick={handleAccountRequest} >Create</Button>
                </div>
            </div>
        </div>

    </div>
    );
  }


