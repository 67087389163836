// Helper enum: defines all possible types of roles for a PeerTECH account
const Roles = {
  SUPER_ADMIN: 'Super-Admin',
  ADMIN: 'Admin',
  PEER: 'Peer',
  SERVICE_USER: 'Service-User',
  MARKET_PLACE_USER: 'market-place-User'
};

export default Roles;
