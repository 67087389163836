import React, { Component } from 'react';
import { storage } from 'firebase';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw, convertFromHTML, ContentState, compositeDecorator } from 'draft-js';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { draftjsToMd, mdToDraftjs } from 'draftjs-md-converter';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
function convertVideoToEmbed(line) {
    let editedLine = line;
    if (line.includes('![](https://www.youtube.com/embed/')) {
        const youtubeLink = line.split('(')[line.split('(').length - 1].slice(0, -1);
        editedLine = `[[ embed url=${youtubeLink} ]]`;
    }
    return editedLine;
}

function prepareMarkdownForWeb(markdownArray) {
    let draftText = '';
    for (const line of markdownArray) {
        draftText += convertVideoToEmbed(line);
        draftText += '\n';
    }
    return draftText;
}

// Draft JS editor
class TextEditorViewer extends Component {
    constructor(props) {
        super(props);
        const webMarkdown = prepareMarkdownForWeb(this.props.page);
        const blocksFromHTML = htmlToDraft(webMarkdown);
        const stateContent = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap,
        );
        this.state = {
            editorState: EditorState.createWithContent(
                stateContent,
                compositeDecorator,
            ),
            hideToolbar: true
        };
    }
    render() {
        const { editorState } = this.state;
        return (
            <div className="resource-view">
                <div className="editor-container">
                    <div role="main" className="editor editorToolbar-hidden">
                        <Editor
                            readOnly
                            editorState={editorState}
                            toolbarHidden={this.state.hideToolbar}
                            toolbarClassName='editorToolbar-hidden'
                        />
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(connect(null, {})(TextEditorViewer));