import React, { Component } from 'react';
import { storage } from 'firebase';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw, convertFromHTML, ContentState, compositeDecorator } from 'draft-js';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { draftjsToMd, mdToDraftjs } from 'draftjs-md-converter';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Button from 'react-bootstrap/Button';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// Convert any 'embed' statements into a thumbnail image linked to a video
// Thumnbail image used to be generated from https://yt-embed.herokuapp.com/ (keeping this comment for now for reference just in case)
// Now using a different format that renders thumbnail on both web and mobile
function convertVideoToMarkdown(line) {
    let editedLine = line;
    const youtubeMatch = line.match(/url=(.*) /); // Find any lines with video URLs
    if (youtubeMatch != null) { // Did it match?
        const videoID = getVideoId(youtubeMatch[1]).id;
        editedLine = `[![YouTube Video](http://img.youtube.com/vi/${videoID}/0.jpg)](http://www.youtube.com/watch?v=${videoID})`;
    }
    return editedLine;
}

// Convert any images linked to YouTube videos into embed-style statements
// For live rendering by Draft JS
function convertVideoToEmbed(line) {
    let editedLine = line;
    // console.log('editedLine', editedLine);
    if (line.includes('![](https://www.youtube.com/embed/')) {
        const youtubeLink = line.split('(')[line.split('(').length - 1].slice(0, -1);
        editedLine = `[[ embed url=${youtubeLink} ]]`;
    }
    // const youtubeMatch = line.match(/\)]\((.*)\)/); // Find any lines with videos linked to images
    // if (youtubeMatch != null) { // Did it match?
    //     editedLine = `[[ embed url=${youtubeMatch[1]} ]]`;
    // }
    return editedLine;
}

function prepareMarkdownForWeb(markdownArray) {
    let draftText = '';
    for (const line of markdownArray) {
        draftText += convertVideoToEmbed(line);
        draftText += '\n';
    }
    return draftText;
}

// Convert DraftJS-style markdown into array of Markdown lines with videos converted to thumbnail format
function prepareMarkdownForMobile(markdown) {
    const markdownArray = [];

    const markdownSplit = markdown.split(/\r?\n/); // Split on both carriage return and newline (Windows vs UNIX)
    for (const line of markdownSplit) {
        if (line !== '') {
            const editedLine = convertVideoToMarkdown(line);
            markdownArray.push(editedLine);
        }
    }
    return (markdownArray);
}

// Draft JS editor
class TextEditor extends Component {
    constructor(props) {
        super(props);
        // const webMarkdown = prepareMarkdownForWeb(this.props.page);
        // const draftData = htmlToDraft(webMarkdown);
        // const contentState = convertFromRaw(draftData);

        const webMarkdown = prepareMarkdownForWeb(this.props.page);
        const blocksFromHTML = htmlToDraft(webMarkdown);
        const stateContent = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap,
        );

        this.state = {
            // editorState: EditorState.createWithContent(contentState),
            editorState: EditorState.createWithContent(
                stateContent,
                compositeDecorator,
            ),
            uploadedImages: [],
        };
        this._uploadImageCallBack = this._uploadImageCallBack.bind(this);
    }
    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };
    focus = () => {
        this.editor.focus();
    };

    // Convert content to Markdown and push to backend
    savePage = () => {
        const content = this.state.editorState.getCurrentContent();
        const markdown = draftToHtml(convertToRaw(content));
        const pageMarkdown = prepareMarkdownForMobile(markdown);
        this.props.savePage(pageMarkdown);
    }

    deletePage = () => {
        this.props.deletePage();
    }

    setEditorReference = (ref) => {
        this.editorReferece = ref;
        ref.focus();
    }

    _uploadImageCallBack(file) {
        let uploadedImages = this.state.uploadedImages;
        var name = new Date().getTime();
        return new Promise(
            (resolve, reject) => {
                console.log(name);
                let uploadTask = storage().ref(`/resources/${name}`).put(file);
                uploadTask.on('state_changed', (snapShot) => {
                    console.log(snapShot);
                }, (err) => {
                    console.log(err);
                }, () => {
                    console.log(name);
                    storage().ref(`/resources/${name}`).getDownloadURL().then(link => {
                        console.log(link);
                        const imageObject = ({ file: file, localSrc: link });
                        uploadedImages.push(imageObject);
                        this.setState({ uploadedImages: uploadedImages });
                        // console.log('File available at', link);
                        resolve({ data: { link } });
                    });
                });
            }
        );
    }
    uploadImageCallBack(file) {
        let uploadedImages = this.state.uploadedImages;
        const imageObject = {
            file: file,
            localSrc: URL.createObjectURL(file),
        };
        uploadedImages.push(imageObject);
        this.setState({ uploadedImages: uploadedImages });
        return new Promise(
            (resolve, reject) => {
                resolve({ data: { link: imageObject.localSrc } });
            }
        );
    }
    render() {
        const { editorState } = this.state;
        return (
            <div className="resource-view">
                <div className="hidden">
                    {/* <Editor
                        editorState={this.state.editorState}
                        onChange={this.onEditorChange}
                        ref={(element) => { this.editor = element; }}
                    /> */}
                </div>
                <div className="editor-container">
                    <div role="main" className="editor">
                        <Editor
                            editorState={editorState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={this.onEditorStateChange}
                            toolbar={{
                                image: { uploadCallback: this._uploadImageCallBack, previewImage: true },
                                inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                            }}
                        />
                    </div>
                </div>
                <Button variant="primary blueButton" onClick={this.savePage}>save page</Button>
                <Button variant="primary blueButton" onClick={this.deletePage}>delete page</Button>
            </div>
        );
    }
}
export default withRouter(connect(null, {})(TextEditor));