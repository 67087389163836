/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Routes, { createOrgRoute } from '../helpers/Routes';
import Roles from '../helpers/Roles';

export default function MarketListPriceRow(props) {
    return (
      <>
          { 
              props.data && props.data.map((item)=>{
                
                return (
                    
                    <div class="listRow table-row link" role="button" >
                      <div class="name">{item.market_place_name}</div>
                      <div class="email">{item.price}</div>
                      <div class="edit"><div role="button" onClick={()=>{
                                props.deleteClick(item.id);
                    }} > <i class="fas fa-trash"></i></div></div>
                  </div>
                );
               
              })
          }                                
          </>
    );
  }

