/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Routes, { createOrgRoute } from '../helpers/Routes';
import Roles from '../helpers/Roles';

// Helper for users view (to display user's details in a list/table)
class ListRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userID: props.user.id,
        };
    }
    // Set state from props
    componentDidMount() {
    }

    // Go to user's specific page
    
    navToUser = () => {

        if(this.props.user.role === Roles.MARKET_PLACE_USER)
        {
            this.props.history.push({ pathname:  Routes.CREAT_MARKET_USER_PRICE, state: { user: this.props.user } });
        }else{
            this.props.history.push({ pathname: createOrgRoute(this.props.user.orgID, Routes.SINGLE_USER), state: { user: this.props.user, orgID: this.props.user.orgID, userID: this.props.user.id } });
        }

        
    }

    // editAccount = (accountID) => {
    //     this.props.history.push({
    //         pathname: `/editAccount/${this.state.userID}`,
    //     });
    // }

    renderRow() {
        if (this.props.user.role === Roles.ADMIN) {
            return (
                <div className="listRow table-row" role="button" tabIndex={0}>
                    <div className="name">
                        {this.props.user.name}
                    </div>
                    <span>&nbsp;</span>
                    <div className="role">
                        {this.props.user.role}
                    </div>
                    <span>&nbsp;</span>
                    <div className="email">
                        {this.props.user.email || 'no email'}
                    </div>
                    <div className="delete">
                        <div
                            role="button"
                            tabIndex={0}
                            onClick={(e) => this.props.deleteUserByID(this.props.user.id, this.props.user.role)}
                        > <i className="fas fa-trash"></i>
                        </div>
                    </div>
                    <div className="mail">
                        <div
                            role="button"
                            tabIndex={0}
                            onClick={(e) => this.props.sendPasswordReset(this.props.user.email)}
                        > <i className="fas fa-paper-plane"></i>
                        </div>
                        {/* <Link
                            to={{
                                pathname: createOrgRoute(this.props.orgID, Routes.ORG_PEOPLE),
                                state: {
                                    orgID: this.props.orgID,
                                },
                            }}
                            onClick={this.deleteUserByID}
                        > <i className="fas fa-paper-plane"></i>
                        </Link> */}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="listRow table-row link" role="button" tabIndex={0}>
                    <div className="name" onClick={this.navToUser}>
                        {this.props.user.name}
                    </div>
                    <span>&nbsp;</span>
                    <div className="role" onClick={this.navToUser}>
                        {this.props.user.role}
                    </div>
                    <span>&nbsp;</span>
                    <div className="email" onClick={this.navToUser}>
                        {this.props.user.email || 'no email'}
                    </div>
                    {
                        (this.props.user.role === Roles.SERVICE_USER || this.props.user.role === Roles.MARKET_PLACE_USER)
                            ? (<div className="edit">
                                <Link
                                    to={{
                                        pathname: `/editAccount/${this.state.userID}`,
                                        state: {
                                            userID: this.state.userID,
                                        },
                                    }}
                                > <i className="fas fa-edit"></i>
                                </Link>
                            </div>)
                            : (<div />)
                    }
                </div >
            );
        }
    }

    // Display all user properties as a row
    render() {
        return (
            this.renderRow()
        );
    }
}

const mapStateToProps = reduxState => (
    {
        orgID: reduxState.orgs.selectedOrgID,
    }
);

export default withRouter(connect(mapStateToProps, null)(ListRow));
