import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchUserPosts, actionPostStatus } from '../firebase';


class PeerPost extends Component {
    constructor(props) {
        super(props);
        console.log(this.props);
        this.state = {
            // userID: this.props.user.id,
            posts: [],
            orgName: "",
        };
    }

    componentDidMount() {
        fetchUserPosts(this.setPosts);
    }

    setPosts = (posts) => {
        this.setState({ posts });
    }

    postAction = (postID, status) => {
        actionPostStatus(postID, status, this.successCallback);
    }

    successCallback = () => {
        this.componentDidMount();
    }

    render() {
        return (
            <div className="page view">
                <div className="pageHeader">
                    <div className="pageText">
                        <h1 className='post-heading'>{this.state.orgName} Peer User Posts</h1>
                        <div className="resource-lib-desc-wrap">
                            <div className="resource-lib-desc">
                                {/* <p>Organization post list</p> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="postList">
                    {
                        this.state.posts.length > 0 ? (
                            this.state.posts.map((post, index) => {
                                if(post.status == 'pending') {
                                    return (
                                        <div className="postTile" key={index}>
                                            <div className="postText">
                                                <h6>{post.title}</h6>
                                                <p>{post.text}</p>
                                                {/* <p>{post.dueDate}</p> */}
                                                <div className='dialog'>
                                                    <button className='blueButton btn btn-primary' onClick={(e) => this.postAction(post.firebaseID, 'confirm')}>Confirm</button>
                                                    <button className='blueButton btn btn-primary' onClick={(e) => this.postAction(post.firebaseID, 'reject')}>Reject</button>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                } else if(post.status == 'confirm') {
                                    return (
                                        <div className="postTile" key={index}>
                                            <div className="postText">
                                                <h6>{post.title}</h6>
                                                <p>{post.text}</p>
                                                {/* <p>{post.dueDate}</p> */}
                                                <div className='dialog'>
                                                    <button className='blueButton btn btn-primary' onClick={(e) => this.postAction(post.firebaseID, 'reject')}>Reject</button>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div className="postTile" key={index}>
                                            <div className="postText">
                                                <h6>{post.title}</h6>
                                                <p>{post.text}</p>
                                                {/* <p>{post.dueDate}</p> */}
                                                <div className='dialog'>
                                                    <button className='blueButton btn btn-primary' onClick={(e) => this.postAction(post.firebaseID, 'confirm')}>Confirm</button>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            })
                        ) : (
                            <div className="emptyData">
                                This peer has no post
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = reduxState => (
    {
        orgID: reduxState.orgs.selectedOrgID,
        allUsers: reduxState.users.orgUsers,
    }
);

export default withRouter(connect(mapStateToProps, { fetchUserPosts, actionPostStatus })(PeerPost));
