import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchMarketList } from '../firebase';
import Roles from '../helpers/Roles';
import MarketListRow from './MarketListRow';


// Shows list/table of all an organization's service users
class MarketList extends Component {
     constructor(props) {
        super(props);
        console.log(this.props);
        this.state = {
            // userID: this.props.user.id,
            marketlist: []
        };
    }


    setMarketList = (marketlist) => {
        this.setState({ marketlist });
    }

    componentDidMount() {
       
        this.props.fetchMarketList(this.setMarketList);
       
    }

    
   render() {
        
        return (
            <div>

                {/*
        <div>
          <Button onClick={this.sortByName}>Sort by name</Button>
        </div> */}
                { this.state.marketlist.length > 0 ? (
                    <div className="userList">
                       <div class="userList">
  <div class="listHeader">
    <h3 class="name">List</h3>
    
    <h3 class="edit">Action</h3>
  </div>
  {<MarketListRow dataMarketList={this.state.marketlist} data={this.state.marketlist}/>}
  

  
  
</div>
                        
                            
                    </div>
                )
                    : (
                        <div className='emptyData'>
                            No Market list yet
                        </div>
                    )}
            </div>
        );
    }
}

const mapStateToProps = reduxState => (
    {
        orgID: reduxState.orgs.selectedOrgID,
        allUsers: reduxState.users.orgMarket,
    }
);

export default withRouter(connect(mapStateToProps, {fetchMarketList})(MarketList));
