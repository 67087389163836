/* eslint-disable no-unused-vars */
/* eslint-disable new-cap */
import arrayMove from 'array-move';
import React, { Component } from 'react';
import { storage } from 'firebase';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { fetchSpecificResource, updateSpecificPart, updateSpecificPartType, updateSpecificResource } from '../firebase';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { setErrorMessage } from '../redux/DispatchHelpers';
import EditPopup from './EditPopup';
import MarkdownEditor from './MarkdownEditor';
import TextEditor from './TextEditor';

// handler, grab this to drag the pages
const DragHandle = SortableHandle(() => <span>:: </span>);

// generates a button using the higher-order component sortableElement
const PageButton = SortableElement(({ value, changePage }) => (
    <div className="pageIcon">
        {/* <DragHandle /> */}
        <Button onClick={changePage} className="pageButton" variant="" value={value}>
            {/* Page  */}
            {value + 1}
        </Button>
    </div>
));

// generates a list of page buttons using the h-o-c SortableContainer
// would prefer to use a key without an array index at all
// but I combined that with the first content paragraph to create pseudo-unique keys
const PageButtonList = SortableContainer(({ pages, changePage }) => {
    return (
        <ul className="pageList">
            {pages.map((page, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <PageButton key={`${page[0]} ${index}`} changePage={changePage} index={index} value={index} />
            ))}
        </ul>
    );
});

// Creates text editor and page CRUD for a resource
class EditResource extends Component {
    constructor(props) {
        super(props);

        this.state = {
            resource: {}, // Current resource (set of pages) to display
            selectedPage: 0, // Which page to display in the editor
            video_link: '',
            readyToView: false,
            copied: false,
            resource_type: 'all',
        };
    }

    componentDidMount() {
        this.loadResource();
    }

    // Fetch resource from backend and select specific page to initially view
    loadResource = (pageIndex = 0) => {
        const callback = (resource) => {
            this.setState({ resource, selectedPage: pageIndex, resource_type: resource.type });
        };
        this.props.fetchSpecificResource(this.props.match.params.resourceID, callback);
    }

    changePage = (event) => {
        this.setState({ selectedPage: Number(event.target.value) });
    }

    // Create new Markdown page object in database
    addPage = () => {
        const { pages } = this.state.resource;
        const newPageIndex = pages.push(['<h1>Page Title</h1>', '<p>Page Content</p>']) - 1; // Add sample page to array

        // Refresh
        const reloadResource = () => {
            this.loadResource(newPageIndex);
        };
        this.props.updateSpecificResource(this.props.match.params.resourceID, pages, reloadResource);
    }

    // Update current Markdown page object in database
    savePage = (pageMarkdown) => {
        const { pages } = this.state.resource;
        pages[this.state.selectedPage] = pageMarkdown; // Update page in array

        // Refresh
        const reloadResource = () => {
            this.loadResource(this.state.selectedPage);
        };
        this.props.updateSpecificResource(this.props.match.params.resourceID, pages, reloadResource);
    }

    // Delete current Markdown page object in database
    deletePage = () => {
        const { pages } = this.state.resource;

        // Ensure resource still has pages
        if (pages.length <= 1) {
            this.props.setErrorMessage('You cannot have no pages in a resource. Consider deleting this resource entirely.');
        } else {
            pages.splice(this.state.selectedPage, 1); // Remove page in array
            const previousPageIndex = Math.max(this.state.selectedPage - 1, 0);

            // Refresh
            const reloadResource = () => {
                this.loadResource(previousPageIndex);
            };
            this.props.updateSpecificResource(this.props.match.params.resourceID, pages, reloadResource);
        }
    }

    // reorders page from oldIndex to newIndex
    // old/newIndex are defined names in react-sortable-hoc and cannot be renamed
    reorderPage = ({ oldIndex, newIndex }) => {
        if (oldIndex === newIndex) return; // don't need to update if no change
        const { pages } = this.state.resource;
        const reorderedPages = arrayMove(pages, oldIndex, newIndex);
        // if moved current page, jump to new location
        // else stay on same page (either same page number, one up, or one down)
        // saves current page automatically
        const reloadResource = () => {
            const { selectedPage } = this.state;
            if (oldIndex === selectedPage) {
                this.loadResource(newIndex);
            } else if ((selectedPage < newIndex) === (selectedPage < oldIndex) && selectedPage !== newIndex) {
                this.loadResource(selectedPage);
            } else if (oldIndex > selectedPage) {
                this.loadResource(selectedPage + 1);
            } else {
                this.loadResource(selectedPage - 1);
            }
        };
        const { resourceID } = this.props.match.params;
        this.props.updateSpecificResource(resourceID, reorderedPages, reloadResource);
    }

    renderPageButtons = () => {
        if (!this.state.resource.pages) { return 'Loading...'; }

        // draggable sortable list
        // pressDelay means wait 150 ms before start drag
        return (
            <div className="pages">
                <PageButtonList
                    useDragHandle
                    axis="y"
                    pressDelay={150}
                    pages={this.state.resource.pages}
                    onSortEnd={this.reorderPage}
                    changePage={this.changePage}
                />
                <Button id="newPageButton" key="addNew" variant="primary blueButton" onClick={this.addPage}>+ </Button>
            </div>

        );
    }

    editTitle = (data) => {
        const reloadResource = () => {
            this.loadResource(this.state.selectedPage);
        };
        // Pass true for Image, false for title
        this.props.updateSpecificPart(this.props.match.params.resourceID, data, reloadResource, false);
    }

    editType = (data) => {
        const reloadResource = () => {
            this.loadResource(this.state.selectedPage);
        };
        // Pass true for Image, false for title
        this.props.updateSpecificPartType(this.props.match.params.resourceID, data, reloadResource);
    }

    onFileChange = (event) => {
        this.setState({ readyToView: true });
        // var name = new Date().getTime();
        return new Promise(
            (resolve, reject) => {
                var name = event.target.files[0].name;
                // console.log(event.target.files[0].name);
                let uploadTask = storage().ref(`/resources/video/${name}`).put(event.target.files[0]);
                uploadTask.on('state_changed', (snapShot) => {
                    // console.log(snapShot);
                }, (err) => {
                    console.log(err);
                }, () => {
                    storage().ref(`/resources/video/${name}`).getDownloadURL().then(link => {
                        this.setState({ video_link: link, readyToView: false, copied: false });
                        resolve({ data: { link } });
                    });
                });
            }
        );
    }

    render() {
        return ( // Gonna Have to do something with img-box style, probably flex it underneath
            <div className="view page resourse-view">
                <div className="file-input">
                    <input
                        type="file"
                        name="file-input"
                        id="file-input"
                        className="file-input__input"
                        disabled={this.state.readyToView}
                        onChange={this.onFileChange}
                    />
                    <label className="file-input__label btn btn-primary blueButton" id={!this.state.readyToView ? 'inputVisible' : 'inputHide'} htmlFor="file-input">
                        <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="upload"
                            className="svg-inline--fa fa-upload fa-w-16"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                        >
                            <path
                                fill="currentColor"
                                d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                            ></path>
                        </svg>
                        <span>{!this.state.readyToView ? 'Upload Video File' : 'Please wait uploading...'}</span>
                    </label>
                    {this.state.video_link ? <div id='videoLinkSection'><div id='videoLink'>{this.state.video_link ? this.state.video_link : "Please upload video then link here"}</div><CopyToClipboard className="btn btn-primary blueButton" id={this.state.copied ? 'btnHide' : 'btnShow'} text={this.state.video_link}
                        onCopy={() => this.setState({ copied: true })}>
                        <button title='Copy'>{!this.state.copied ? <i className="fas fa-copy" title='Copy'></i> : <i className="fas fa-check-square" title='Copied'></i>}</button>
                    </CopyToClipboard></div> : ""}
                </div>
                <div className="boxshadow p-3">
                    <div className="img-box">
                        <h2 className='d-inline'>Title: {this.state.resource.title}</h2>
                        <EditPopup
                            editorState={this.state.editTitle}
                            editTitle={this.editTitle}
                        />
                    </div>
                    <h2>Page {this.state.selectedPage + 1}</h2>
                    <div className="editContentHalf">
                        <div className="text-center resourceRadioSection">
                            <input type="radio" value="all" name="resource_type" checked={this.state.resource_type === "all"} className="radioResource" onChange={(e) => this.editType('all')} /> All
                            <input type="radio" value="peer" name="resource_type" checked={this.state.resource_type === "peer"} className="radioResource" onChange={(e) => this.editType('peer')} /> Peer
                            <input type="radio" value="service_user" name="resource_type" checked={this.state.resource_type === "service_user"} className="radioResource" onChange={(e) => this.editType('service_user')} /> Service User
                        </div>
                        <div className="editContentBox">
                            {this.state.resource.pages && this.renderPageButtons()}
                            {this.state.resource.pages && (
                                <TextEditor
                                    savePage={this.savePage}
                                    deletePage={this.deletePage}
                                    key={this.state.selectedPage}
                                    page={this.state.resource.pages[this.state.selectedPage]}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(connect(null, {
    fetchSpecificResource, updateSpecificResource, setErrorMessage, updateSpecificPart,updateSpecificPartType,
})(EditResource));
