import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { addResource } from '../firebase';
class CreateModuleButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            organizations: [],
        };
    }
    addResourceCallback = (newID) => {
        this.props.history.push({
            pathname: `/resourceLibrary/${newID}/edit`,
        });
    }
    // Create new resource with default parameters and push to database
    addResource = () => {
        const newResource = {
            title: 'New Resource',
            type: 'all',
            pages: {
                0: ['<h1>Page Title</h1>', '<p>Page Content</p>'],
            },
        };
        this.props.addResource(newResource, this.addResourceCallback);
    }

    render() {
        return (
            <div id='module_button_section' className="boxshadow">
                <div id="moduleButtonText">
                    <div className='float-right'>
                        <Button className="addButton m-auto" id="purplebackground" onClick={this.addResource}>
                            + Create Module
                        </Button>
                    </div>
                    <h1> Modules </h1>
                    <p> Preview, edit, and publish modules here. Modules must be published to be available to organizations and users. Drag-and-drop published modules to change the order they will appear in. Note: new modules will be created in the drafts tab. </p>
                </div>
                {/* <img id="homeImg" src={vectorArt} alt="noimg" /> */}
                {/* <div id="moduleButtonText" className="pageText">
                    <h1>Resource Library</h1>
                    <div className="resource-lib-desc-wrap">
                        <div className="resource-lib-desc">
                            <p>
                                Preview, edit, and publish resources here.
                                Resources must be published to be available to organizations and users.
                                Drag-and-drop published resources to change the order they will appear in.
                                Note: new resources will be created in the drafts tab.
                            </p>
                        </div>
                    </div>
                </div> */}
            </div>
        );
    }
}

const mapStateToProps = reduxState => (
    {
        role: reduxState.auth.role,
    }
);

export default withRouter(connect(mapStateToProps, { addResource })(CreateModuleButton));