import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchOrgUserPosts, fetchSingleOrganization } from '../firebase';


class OrgPeerPost extends Component {
    constructor(props) {
        super(props);
        console.log(this.props);
        this.state = {
            // userID: this.props.user.id,
            posts: [],
            orgName: "",
        };
    }

    componentDidMount() {
        if (this.props.orgID !== '') {
            fetchSingleOrganization(this.props.orgID, this.setOrgName);
            fetchOrgUserPosts(this.props.orgID, this.setPosts);
        }
    }

    setOrgName = (organization) => {
        this.setState({ orgName: organization.name });
    }

    setPosts = (posts) => {
        this.setState({ posts });
    }

    render() {
        return (
            <div className="page view">
                <div className="pageHeader">
                    <div className="pageText">
                        <h1 className='post-heading'>{this.state.orgName} Organization Peer User Posts</h1>
                        <div className="resource-lib-desc-wrap">
                            <div className="resource-lib-desc">
                                {/* <p>Organization post list</p> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="postList">
                    {
                        this.state.posts.length > 0 ? (
                            this.state.posts.map((post, index) => {
                                return (
                                    <div className="postTile" key={index}>
                                        <div className="postText">
                                            <h6>{post.title}</h6>
                                            <p>{post.text}</p>
                                            {/* <p>{post.dueDate}</p> */}
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div className="emptyData">
                                This peer has no post
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = reduxState => (
    {
        orgID: reduxState.orgs.selectedOrgID,
        allUsers: reduxState.users.orgUsers,
    }
);

export default withRouter(connect(mapStateToProps, { fetchOrgUserPosts, fetchSingleOrganization })(OrgPeerPost));
