import React, { Component } from 'react';
import { withRouter,useLocation , useHistory} from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Input } from 'reactstrap';
import { connect } from 'react-redux';
import { fetchMarketList , createMarketUserPrice ,fetchMarketUserPriceList, deleteMarketPrice} from '../firebase';
import Roles from '../helpers/Roles';
import MarketListPriceRow from './MarketListPriceRow';


// Shows list/table of all an organization's service users
class CreateMarketUserPrice extends Component {
     
     constructor(props) {
        super(props);
       // console.log(props.location.state.user.id);
        this.state = {
            // userID: this.props.user.id,
            price: '',
            market_place_id: '',
            market_name:'',
            marketlist: [],
            marketpricelist: [],
        };
        

    }



    // 

    setMarketList = (marketlist) => {
        this.setState({ marketlist });

        if(marketlist.length>0){
            this.setState({market_place_id: marketlist[0].id });
            this.setState({market_name: marketlist[0].name });
        }

    }

    setMarketListPrice = (marketpricelist) => {

        this.setState({ marketpricelist });
        console.log("waw",marketpricelist);
    }

    onPriceChange = (event) => {
        this.setState({ price: event.target.value });
    }

    onMarketChange = (event) => {
       
        this.setState({ market_place_id: event.target.value });

       var dataSelect= this.state.marketlist.find((item)=>item.id===event.target.value);
       this.setState({ market_name: dataSelect.name });

    }

    componentDidMount() {
       
        this.props.fetchMarketList(this.setMarketList);
        this.props.fetchMarketUserPriceList(this.props.location.state.user.id,this.setMarketListPrice);
       
    }

    handleAccountRequest = (event) => {

        if (this.state.price === '' || this.state.market_place_id === '') {
            this.props.setErrorMessage('Please fill in all the required information');
            return;
        }

       createMarketUserPrice({"user_id":this.props.location.state.user.id,"price":this.state.price,"market_place_id":this.state.market_place_id,"market_place_name":this.state.market_name},()=>{
          // useHistory().go(0);
          this.setState({ price: '' });
          
          this.props.fetchMarketUserPriceList(this.props.location.state.user.id,this.setMarketListPrice);

        });
    }

    handleDeleteRequest = (id) => {
        
        this.props.deleteMarketPrice(id,()=>{
         
          this.props.fetchMarketUserPriceList(this.props.location.state.user.id,this.setMarketListPrice);

        });
    }    

    createMarketList = () => {
        
        const MarketListData =this.state.marketlist.map((item,index) => {
            
            return (
                <option key={index} value={item.id}>{item.name}</option>
            );
        });
        return MarketListData;
    }

    
   render() {
        
        return (
            <div className="page view">
            <div className="pageHeader text-justify">
                <div>
                    <h1>Edit List</h1>
                </div>
            </div>
            <div id="createAccount">
                <div id="createForm">
                <div className="select">
                                <div>
                                    <select placeholder="Organization" value={this.state.market_place_id} onChange={this.onMarketChange} className="form-control input-field">
                                        {this.createMarketList()}
                                    </select>
                                </div>
                            </div>
                    <Input placeholder="Price" onChange={this.onPriceChange} className="input-field" value={this.state.price} />
                    <div>
                        <Button className="blueButton btn-block" onClick={this.handleAccountRequest} >Add</Button>
                    </div>
                </div>
            </div>
            
            <div>

            { this.state.marketpricelist.length > 0 ? (
                <div className="userList">
                    
            <div class="listHeader">
            <h3 class="name">Name</h3>

            <h3 class="email">Price</h3>
            <h3 class="edit">Action</h3>
            
            </div>
            {<MarketListPriceRow dataMarketList={this.state.marketpricelist} data={this.state.marketpricelist} deleteClick={this.handleDeleteRequest} />}




            </div>
                    
                        
               
            )
                : (
                    <div className='emptyData'>
                        No Market list yet
                    </div>
                )}
            </div>

            </div>
        );
    }
}

const mapStateToProps = reduxState => (
    {
        orgID: reduxState.orgs.selectedOrgID,
        allUsers: reduxState.users.orgMarket,
    }
);

export default withRouter(connect(mapStateToProps, {fetchMarketList,fetchMarketUserPriceList,deleteMarketPrice})(CreateMarketUserPrice));
